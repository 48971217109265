import React from "react";
import { CircularProgress, Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({ name: "LoadingButton" })((theme, params, classes) => ({
    buttonWrapper: {
		position: "relative"
	},
	buttonProgress: {
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

export const LoadingButton = (props) => {
    const { classes } = useStyles();
    const { loading, children, disabled, className, ...rest } = props;

    return (
        <span className={classes.buttonWrapper}>
            <Button disabled={loading || disabled} className={className} {...rest}>{children}</Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </span>
    );
};

LoadingButton.defaultProps = {
    variant: "contained",
    color: "primary"
};

export default LoadingButton;
