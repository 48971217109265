import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { TextSkeleton } from "./Skeleton";
import { Switch, FormControlLabel, Grid, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useFormBehaviorContext } from "./FormBehaviorContext";

const useSwitchStyles = makeStyles({ name: "FormSwitchField" })(() => ({
	twoLabelSwitchContainer: {
		marginLeft: "11px"
	}
}));

const CustomSwitchField = props => {
	const {
		checked: checkedValue, outputMode, label,
		inverted, onChange, customSwitchClasses, frontLabel, backLabel,
		labelPlacement, ...rest
	} = props;

	const { classes } = useSwitchStyles();

	const handleChange = e => {
		let checked = e.target.checked;
		if (inverted) checked = !checked;

		if (outputMode === "string") {
			onChange(checked.toString());
		} else {
			onChange(checked);
		}
	};

	let checked = checkedValue === "true" || checkedValue === true;

	if (inverted) {
		checked = !checked;
	}

	let control = (<Switch data-test={`${rest.name}-switch-field`} classes={customSwitchClasses} checked={checked} onChange={handleChange} {...rest} />);
	if (frontLabel && backLabel) {
		control = (
			<Grid container alignItems="center" className={classes.twoLabelSwitchContainer}>
				<Grid item><Typography>{frontLabel}</Typography></Grid>
				<Grid item>
					{control}
				</Grid>
				<Grid item><Typography>{backLabel}</Typography></Grid>
			</Grid>
		);
	}

	return (
		<FormControlLabel
			control={control}
			label={label}
			labelPlacement={labelPlacement}
			sx={{
				"&.MuiFormControlLabel-labelPlacementStart": {
					marginLeft: 0
				}
			}}
		/>
	);
};

export const FormSwitchField = props => {
	const behavior = useFormBehaviorContext();
	const { control } = useFormContext();

	const { name, rules, disabled: disabledProp, defaultValue, isSkeleton, ...rest } = props;

	const disabled = disabledProp || behavior?.isReadOnly || behavior?.isLoading;

	if (isSkeleton || behavior?.isSkeleton) {
		return (<TextSkeleton className={rest.className} height={38} width={"50%"} />);
	}

	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			defaultValue={defaultValue}
			render={({ field: { onChange, onBlur, value, name } }) => (
				<CustomSwitchField
					onChange={value => onChange(value)}
					checked={value}
					name={name}
					disabled={disabled}
					{...rest}
				/>
			)}
		/>
	);
};

FormSwitchField.defaultProps = {
	outputMode: "boolean",
	color: "secondary",
	label: "",
	defaultValue: false
};

export default FormSwitchField;