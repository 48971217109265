import React from "react";
import clsx from "clsx";
import { makeStyles } from "tss-react/mui";
import { useFormContext, Controller } from "react-hook-form";
import { TextSkeleton } from "./Skeleton";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useFormBehaviorContext } from "./FormBehaviorContext";

const useStyles = makeStyles({ name: "FormCheckboxField" })(theme => ({
	formCheckboxPrimary: {
		height: 56
	},
	formCheckboxSecondary: {
		height: 72,
		paddingTop: 18
	}
}));

const CustomCheckboxField = props => {
	const { classes } = useStyles();
	const { checked, disabled, outputType, onChange, label, layout, ...rest } = props;

	const handleChange = e => {
		if (outputType === "string") {
			onChange(e.target.checked.toString());
		} else {
			onChange(e.target.checked);
		}
	};

	return (
		<FormControlLabel
			control={
				<Checkbox color="primary" onChange={handleChange} disabled={disabled} checked={checked === "true" || checked === true} {...rest} />
			}
			className={clsx(layout === "primary" && classes.formCheckboxPrimary, layout === "secondary" && classes.formCheckboxSecondary)}
			label={label ?? ""}
		/>
	);
};

export const FormCheckboxField = props => {
	const behavior = useFormBehaviorContext();
	const { control } = useFormContext();

	const { name, rules, disabled: disabledProp, defaultValue, isSkeleton, ...rest } = props;

	const disabled = disabledProp || behavior?.isReadOnly || behavior?.isLoading;

	if (isSkeleton || behavior?.isSkeleton) {
		return (<TextSkeleton className={rest.className} height={42} width={42} />);
	}

	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			defaultValue={defaultValue}
			render={({ field: { onChange, onBlur, value, name } }) => (
				<CustomCheckboxField
					onChange={value => onChange(value)}
					checked={value}
					name={name}
					disabled={disabled}
					{...rest}
				/>
			)}
		/>
	);
};

FormCheckboxField.defaultProps = {
	outputType: "boolean",
	defaultValue: false,
	layout: "primary"
};

export default FormCheckboxField;