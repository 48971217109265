import React from "react";
import clsx from "clsx";
import { makeStyles } from "tss-react/mui";
import { Box, FormHelperText } from "@mui/material";
import { findError } from "./formHelpers";
import { useFormContext, Controller } from "react-hook-form";
import { nonEmptyString } from "./utils";

const useStyles = makeStyles({ name: "FormStaticField" })(theme => ({
	error: {
		color: theme.palette.error.main
	}
}));

export const FormStaticField = props => {
	const { classes } = useStyles();
	const {
		control,
		formState: {
			errors
		}
	} = useFormContext();
	const { name, render, rules, defaultValue, errorMessage: errorMessageProp, boxComponent } = props;

	const error = findError(name, errors);
	const hasError = error != null;
	const errorMessage = nonEmptyString(error?.message, errorMessageProp);

	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			render={({ field: { onChange, onBlur, value, name } }) => {
				return (
					<Box component={boxComponent ?? "div"} className={clsx(hasError && classes.error)}>
						<input type="hidden" value={value ?? ""} />
						{render ? render(value) : (<Box component="span">{value + ""}</Box>)}
						{hasError && <FormHelperText error={true}>{errorMessage}</FormHelperText>}
					</Box>

				);
			}}
			defaultValue={defaultValue ?? null}
		/>
	);
};

export default FormStaticField;