import React from "react";
import TextField from "@mui/material/TextField";
import { TextSkeleton } from "./Skeleton";
import { useFormContext, Controller } from "react-hook-form";
import { findError } from "./formHelpers";
import { useFormBehaviorContext } from "./FormBehaviorContext";
import { nonEmptyString } from "./utils";

const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const emailValidRule = message => {
	return value => {
		return value == null || value === "" || emailRegExp.test(value) || message;
	};
};

const CustomTextField = props => {
	const { readOnly, maxLength, value, onChange, onBlur, rows, ...rest } = props;

	const handleBlur = e => {
		const trimmedValue = e.target.value.trim();
		onChange(trimmedValue);
		onBlur(trimmedValue);
	};

	const handleChange = e => {
		onChange(e.target.value);
	};

	return (
		<TextField
			value={value ?? ""}
			margin="none"
			variant="outlined"
			rows={rows ?? 4}
			onBlur={handleBlur}
			onChange={handleChange}
			InputProps={{
				readOnly: readOnly
			}}
			inputProps={{
				maxLength: maxLength
			}}
			{...rest}
		/>);
};

export const FormTextField = props => {
	const behavior = useFormBehaviorContext();
	const {
		trigger,
		control,
		formState: {
			errors
		}
	} = useFormContext();

	const { readOnly: readOnlyProp, disabled: disabledProp, rules, name, errorMessage: errorMessageProp,
		fieldType, isSkeleton, maxLength, helperText, defaultValue, skipValidationTrigger, ...rest } = props;

	const readOnly = readOnlyProp || behavior?.isReadOnly;
	const disabled = disabledProp || behavior?.isLoading || readOnly;

	if (isSkeleton || behavior?.isSkeleton) {
		return (<TextSkeleton className={rest.className} height={70} />);
	}

	const error = findError(name, errors);
	const hasError = error != null;
	const errorMessage = nonEmptyString(error?.message, errorMessageProp);

	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			render={({ field: { onChange, onBlur, value, name } }) => {
				return (
					<CustomTextField
						onBlur={value => {
							if (hasError && !skipValidationTrigger) {
								trigger(name);
							}

							onBlur(value);
						}}
						onChange={value => onChange(value)}
						value={value ?? ""}
						name={name}
						error={hasError}
						helperText={hasError ? errorMessage : helperText}
						disabled={disabled}
						readOnly={readOnly}
						maxLength={maxLength}
						type={fieldType}
						data-test={`${name}-field`}
						{...rest}
					/>
				);
			}}
			defaultValue={defaultValue ?? null}
		/>
	);
};

FormTextField.defaultProps = {
	helperText: " "
};

export default FormTextField;