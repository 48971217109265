export const getByPropertyPath = (obj, path, def) => {
	let stringToPath = function (path) {

		if (typeof path !== 'string') return path;

		let output = [];

		path.split('.').forEach(function (item, index) {
			item.split(/\[([^}]+)\]/g).forEach(function (key) {
				if (key.length > 0) {
					output.push(key);
				}
			});
		});
		return output;
	};

	path = stringToPath(path);
	var current = obj;

	for (let i = 0; i < path.length; i++) {
		if (!current[path[i]]) return def;
		current = current[path[i]];
	}

	return current;
};

export const findError = (fieldName, errors) => {
	if (errors) {
		return getByPropertyPath(errors, fieldName);
	}
	return null;
}

export const buildFormName = (...args) => {
	return [...args].join(".");
}