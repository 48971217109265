import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { makeStyles } from "tss-react/mui";
import { FormBehaviorProvider } from "./FormBehaviorContext";
import clsx from "clsx";

const useStyles = makeStyles({ name: "FormContainer" })(theme => ({
	formContainer: {
		position: "relative",
		flexGrow: 1,
		display: "flex",
		flexWrap: "wrap",
		width: "100%"
	}
}));

export const FormContainer = props => {
	const { handleSubmit: handleSubmitProps, isLoading, isSkeleton, isReadOnly, validationmode, className, ...rest } = props;

	const validationMode = validationmode ?? "onSubmit";
	const methods = useForm({
		mode: validationMode,
		reValidateMode: validationMode,
		shouldUnregister: true
	});
	const { handleSubmit } = methods;

	const { classes } = useStyles();
	return (
		<FormBehaviorProvider isLoading={isLoading} isSkeleton={isSkeleton} isReadOnly={isReadOnly}>
			<FormProvider {...methods} >
				<form className={clsx(classes.formContainer, className)} noValidate autoComplete="off" {...rest} onSubmit={handleSubmit(handleSubmitProps)}>
					{props.children}
				</form>
			</FormProvider>
		</FormBehaviorProvider>
	);
};

export default FormContainer;