import * as React from "react";

const FormBehaviorContext = React.createContext(null);

export const useFormBehaviorContext = () => {
    return React.useContext(FormBehaviorContext);
}

export const FormBehaviorProvider = props => {
    const { children, isLoading, isSkeleton, isReadOnly } = props;

    return (
        <FormBehaviorContext.Provider
            value={{ isLoading, isSkeleton, isReadOnly }}
        >
            {children}
        </FormBehaviorContext.Provider>
    );
}