import React from "react";
import { Paper } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import Autocomplete from "@mui/material/Autocomplete";
import { useFormContext, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { findError } from "./formHelpers";
import { nonEmptyString } from "./utils";
import { useFormBehaviorContext } from "./FormBehaviorContext";
import { TextSkeleton } from "./Skeleton";

const usePaperStyles = makeStyles({ name: "FormAutocomplete" })({
    root: {
        margin: 0
    }
});

const FormAutocompletePaper = (props) => {
    const { classes: paperClasses } = usePaperStyles();

    return (
        <Paper {...props} classes={paperClasses} />
    );
};

export const FormAutocomplete = (props) => {
    const behavior = useFormBehaviorContext();
    const {
        control,
        trigger,
        formState: {
            errors
        }
    } = useFormContext();

    const {
        name, options, label, className, allowCustomText, helperText, flatList,
        rules, errorMessage, clearValue, defaultValue, disabled: disabledProp,
        onChangeHandler, listItemComponent, bottomComponent, ...rest } = props;

    if (behavior?.isSkeleton) {
        return <TextSkeleton className={className} height={70} />;
    }

    const autocompleteOptions = flatList ? options.map(o => o.value) : options;

    const error = findError(name, errors);
    const hasError = error != null;
    const errorDescription = nonEmptyString(error?.message, errorMessage);
    const helperTextV = hasError ? errorDescription : helperText;

    const readOnly = behavior?.isReadOnly;
    const disabled = disabledProp || behavior?.isLoading || readOnly;

    return (
        <Controller
            render={({ field: { onChange, onBlur, value, name } }) => (
                <Autocomplete
                    {...rest}
                    PaperComponent={FormAutocompletePaper}
                    name={name}
                    value={value}
                    freeSolo={allowCustomText}
                    openOnFocus
                    disabled={disabled}
                    renderOption={(props, option) => renderOptions(props, flatList, options, option, listItemComponent)}
                    getOptionLabel={(option) => getOptionLabel(flatList, option, autocompleteOptions)}
                    isOptionEqualToValue={(option, value) => getOptionSelected(flatList, option, value)}
                    options={autocompleteOptions}
                    className={className}
                    renderInput={(params) => {
                        let selectedOption = null;
                        if (bottomComponent) {
                            selectedOption = autocompleteOptions.find(a => a.value === value);
                        }

                        return (
                            <>
                                <TextField
                                    {...params}
                                    label={label}
                                    variant="outlined"
                                    margin="none"
                                    rows={4}
                                    error={hasError}
                                    helperText={helperTextV ?? ""}
                                    data-test={`${name}-autocomplete-field`}
                                />
                                {selectedOption && bottomComponent(selectedOption)}
                            </>
                        );
                    }}
                    onInputChange={(e, option) => {
                        // this is needed because autoSelect option is set to false, otherwise the custom entered text by the user is not persisted in the input field
                        if (e && allowCustomText) {
                            onChange(option);
                        }
                    }}
                    onChange={(e, option) => {
                        // Set to 'clearValue' if user clicks on the 'x' button
                        if (option === null && clearValue && e.type === 'click') {
                            onChange(clearValue);
                            return;
                        }

                        // Prevent triggering onChange event when user clears the input text
                        if (option === null && e.type === 'change' && !allowCustomText) {
                            return;
                        }

                        if (option) {
                            const newValue = flatList ? option : option?.value;
                            onChange(newValue);

                            if (onChangeHandler) {
                                onChangeHandler(option);
                            }
                        }

                        if (hasError) {
                            trigger(name);
                        }

                    }}
                    onBlur={(_, option) => {
                        if (hasError) {
                            trigger(name);
                        }

                        if (option) {
                            onBlur(flatList ? option : option?.value);
                        }
                    }}
                />
            )}
            name={name}
            control={control}
            rules={rules}
            defaultValue={null}
        />
    );
};

const renderFlatListOptions = (props, options, value) => {
    const label = options.find(o => o.value === value)?.label ?? "";
    return (<li {...props}>{label}</li>);
};

const renderComplexListOptions = (props, options, option, listItemComponent) => {
    const selectedOption = options.find(o => o.value === option.value);

    if (!listItemComponent) {
        return (<li {...props}>{selectedOption?.label ?? ""}</li>);
    }

    return (<li {...props}>{listItemComponent(selectedOption)}</li>);
};

const renderOptions = (props, flatList, options, option, listItemComponent) => {
    if (flatList) {
        return renderFlatListOptions(props, options, option);
    }

    return renderComplexListOptions(props, options, option, listItemComponent);
};

const getOptionLabel = (flatList, option, autocompleteOptions) => {
    if (flatList) {
        return option;
    } else if (typeof option === 'object') {
        return option?.label ?? "";
    } else {
        return autocompleteOptions.find(o => o.value === option)?.label ?? "";
    }
};

const getOptionSelected = (flatList, option, value) => {
    if (flatList) {
        return option === value;
    } else if (typeof value === 'object') {
        return option.value === value.value;
    } else {
        return option.value === value;
    }
};

FormAutocomplete.defaultProps = {
    helperText: " "
};