
import Big from "big.js";

export const nonEmptyString = (value, defaultValue = null) => {
	return value == null || value === "" ? defaultValue : value;
}

export const parseBigNumber = (value) => {
	try {
		return new Big(value);
	} catch (err) {
		return NaN;
	}
};

export const NumericConstants = {
	MinInt64: parseBigNumber("-9223372036854775808"),
	MaxInt64: parseBigNumber("9223372036854775807"),

	MinUInt64: parseBigNumber("0"),
	MaxUInt64: parseBigNumber("18446744073709551615"),

	MinDouble: parseBigNumber("-1.79769313486232E+308"),
	MaxDouble: parseBigNumber("1.79769313486232E+308"),

	MinSingle: parseBigNumber("-3.402823E+38"),
	MaxSingle: parseBigNumber("3.402823E+38"),

	MinByte: parseBigNumber("0"),
	MaxByte: parseBigNumber("255"),

	MinSByte: parseBigNumber("-128"),
	MaxSByte: parseBigNumber("127"),

	MinInt16: parseBigNumber("-32768"),
	MaxInt16: parseBigNumber("32767"),

	MinInt32: parseBigNumber("-2147483648"),
	MaxInt32: parseBigNumber("2147483647"),

	MinUInt32: parseBigNumber("0"),
	MaxUInt32: parseBigNumber("4294967295")
};