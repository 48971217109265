import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, FormHelperText } from "@mui/material";
import { TextSkeleton } from "./Skeleton";
import { useFormContext, Controller } from "react-hook-form";
import { findError } from "./formHelpers";
import { useFormBehaviorContext } from "./FormBehaviorContext";
import { nonEmptyString } from "./utils";

const normalizeValue = (value, options, nullValue) => {
	if (nullValue != null && value == null) {
		return nullValue;
	}

	if (options) {
		if (!options.find(opt => opt.value === value)) {
			return options.length > 0 ? options[0].value : null;
		}
		return value;
	}

	return null;
};

const checkValue = (value, emptyValue) => {
	return value != null && value !== emptyValue;
};

export const radioGroupRequiredRule = (message, emptyValue = null) => {
	return value => checkValue(value, emptyValue) || message;
};

const CustomRadioGroup = props => {

	const { value, name, options, onChange, onBlur, nullValue, label, helperText, error, ...rest } = props;

	const normalizedValue = normalizeValue(value, options, nullValue);

	//Ensure that the form values are existing in the list.
	useEffect(() => {
		if (normalizedValue !== value && (nullValue == null || normalizedValue !== nullValue)) {
			onChange(normalizedValue);
		}
	});

	const handleChange = e => {
		const targetValue = e.target.value;
		const newValue = nullValue != null && targetValue === nullValue ? null : targetValue;

		onChange(newValue);
		onBlur(newValue);
	};

	return (
		<FormControl component="fieldset" {...rest}>
			<FormLabel component="legend" style={{ marginBottom: "10px" }}>{label}</FormLabel>
			<RadioGroup aria-label={label} name={name} value={normalizedValue} onChange={handleChange}>
				{
					options && options.map(opt =>
						(<FormControlLabel key={opt.value} value={opt.value} control={<Radio color="primary" />} label={opt.label} />)
					)
				}
			</RadioGroup>
			<FormHelperText error={error}>{helperText}</FormHelperText>
		</FormControl>
	);
};

export const FormRadioGroup = props => {
	const behavior = useFormBehaviorContext();
	const {
		control,
		trigger,
		formState: {
			errors
		}
	} = useFormContext();

	const {
		name, errorMessage: errorMessageProp, isSkeleton, rules,
		disabled: disabledProp, helperText: helperTextProp,
		defaultValue: defaultValueProp, ...rest
	} = props;

	const disabled = disabledProp || behavior?.isReadOnly || behavior?.isLoading;

	if (isSkeleton || behavior?.isSkeleton) {
		return (<TextSkeleton className={rest.className} height={70} />);
	}

	const error = findError(name, errors);
	const hasError = error != null;

	const errorMessage = nonEmptyString(error?.message, errorMessageProp);
	const helperText = hasError ? errorMessage : helperTextProp;
	const defaultValue = defaultValueProp ?? null;

	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			defaultValue={defaultValue}
			render={({ field: { onChange, onBlur, value, name } }) => (
				<CustomRadioGroup
					onBlur={value => {
						if (hasError) {
							trigger(name);
						}
						onBlur(value);
					}}
					onChange={value => onChange(value)}
					value={value}
					name={name}
					error={hasError}
					helperText={hasError ? errorMessage : helperText}
					disabled={disabled}
					{...rest}
				/>
			)}
		/>
	);
};

FormRadioGroup.propTypes = {
	options: PropTypes.array.isRequired
};

FormRadioGroup.defaultProps = {
	nullValue: null
};

export default FormRadioGroup;