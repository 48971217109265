import React from "react";
import { useFormContext } from "react-hook-form";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles({ name: "FormGeneralErrorMessage" })((theme, params, classes) => ({
    errorText: {
		color: theme.palette.error.main
	},
}));



export const FormGeneralErrorMessage = props => {
	const { classes } = useStyles();
	const methods = useFormContext();

	const { message, errors: errorsFromProp } = props;

	const errors = methods ? methods.errors : errorsFromProp;
	
	return errors && Object.keys(errors).length > 0 ? (
		<span className={classes.errorText}>
			{message}
		</span>
	) : null;
}

export default FormGeneralErrorMessage;