import React from "react";
import clsx from "clsx";
import { makeStyles } from "tss-react/mui";
import { Box } from "@mui/material";
import { grey } from '@mui/material/colors';

const useStyles = makeStyles({ name: "FormValueDisplay"})((theme, params, classes) => ({
	formValueDisplay: {
		fontSize: "0.875rem",
		[`& .${classes.label}`]: {
			zIndex: 1,
			fontSize: "0.8rem",
			color: "rgba(0, 0, 0, 0.54)",
			padding: theme.spacing(0,1,0,1)
		},
		[`& .${classes.content}`]: {
			minHeight: 54,
			backgroundColor: grey[200],
			borderRadius: 4,
			padding: theme.spacing(2),
			fontSize: "1rem",
			overflowWrap: "anywhere"
		}
	},
	label: {},
	content: {}
}));

export const FormValueDisplay = props => {
	const { classes } = useStyles();
	const { content, label, className } = props;

	return (
		<Box component="div" className={clsx(classes.formValueDisplay, className)}>
			<Box component="div" className={classes.label}>{label}</Box>
			<Box component="div" className={classes.content}>{content}</Box>
		</Box>
	);
}