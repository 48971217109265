import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { TextField, MenuItem, Checkbox, ListItemText } from "@mui/material";
import { TextSkeleton } from "./Skeleton";
import { useFormContext, Controller } from "react-hook-form";
import { findError } from "./formHelpers";
import { useFormBehaviorContext } from "./FormBehaviorContext";
import { nonEmptyString } from "./utils";

const normalizeValue = (value, options, multiple, nullValue) => {
	if (nullValue != null && value == null) {
		return nullValue;
	}

	if (multiple) {
		const normalized = options && Array.isArray(value)
			? options.filter(opt => value.indexOf(opt.value) > -1).map(opt => opt.value)
			: [];
		return Array.isArray(value) && normalized.length === value.length ? value : normalized;
	}

	if (options) {
		if (!options.find(opt => opt.value === value)) {
			return options.length > 0 ? options[0].value : null;
		}
		return value;
	}

	return null;
};

const renderValue = (selected, options, multiple) => {
	if (!multiple) {
		return options.find(opt => opt.value === selected).label;
	}

	return Array.isArray(selected) ? options.filter(opt => selected.indexOf(opt.value) > -1)
		.map(opt => opt.label).join(",") : "";
};

const checkMultiple = (value) => {
	return Array.isArray(value) ? value.length > 0 : false;
};

const checkSingle = (value, emptyValue) => {
	return value != null && value !== emptyValue;
};

export const selectRequiredRule = (message, isMultiple, emptyValue = null) => {
	return isMultiple
		? value => checkMultiple(value) || message
		: value => checkSingle(value, emptyValue) || message;
};

const CustomSelect = props => {

	const { value, options, multiple, onChange, onBlur, nullValue, ...rest } = props;

	const normalizedValue = normalizeValue(value, options, multiple, nullValue);

	//Ensure that the form values are existing in the list.
	useEffect(() => {
		if (normalizedValue !== value && (nullValue == null || normalizedValue !== nullValue)) {
			onChange(normalizedValue);
		}
	});

	const handleChange = e => {
		const targetValue = e.target.value;
		const newValue = nullValue != null && targetValue === nullValue ? null : targetValue;
		onChange(newValue);
		onBlur(newValue);
	};

	const menuProps = {
		//Fix anchor to bottom of select component (if there is enough space below)
		anchorOrigin: {
			vertical: "bottom",
			horizontal: "left"
		},
		transformOrigin: {
			vertical: "top",
			horizontal: "left"
		}
	};

	return (
		<TextField
			select={true}
			variant="outlined"
			margin="none"
			value={normalizedValue}
			onChange={handleChange}
			SelectProps={{
				multiple: multiple,
				renderValue: selected => renderValue(selected, options, multiple),
				MenuProps: menuProps
			}}
			{...rest}
		>
			{
				options && options.map(opt =>
					multiple
						? (
							<MenuItem key={opt.value} value={opt.value}>
								<Checkbox checked={normalizedValue.indexOf(opt.value) > -1} />
								<ListItemText primary={opt.label} />
							</MenuItem>
						)
						: (<MenuItem key={opt.value} value={opt.value}>{opt.label}</MenuItem>)

				)
			}
		</TextField>
	);
};

export const FormSelect = props => {
	const behavior = useFormBehaviorContext();
	const {
		control,
		trigger,
		formState: {
			errors
		}
	} = useFormContext();

	const {
		name, errorMessage: errorMessageProp, isSkeleton, rules,
		disabled: disabledProp, helperText: helperTextProp, multiple,
		defaultValue: defaultValueProp, shouldValidateOnBlur, ...rest
	} = props;

	const disabled = disabledProp || behavior?.isReadOnly || behavior?.isLoading;

	if (isSkeleton || behavior?.isSkeleton) {
		return (<TextSkeleton className={rest.className} height={70} />);
	}

	const error = findError(name, errors);
	const hasError = error != null;

	const errorMessage = nonEmptyString(error?.message, errorMessageProp);
	const helperText = hasError ? errorMessage : helperTextProp;
	const defaultValue = defaultValueProp ?? (multiple ? [] : null);

	return (
		<Controller
			control={control}
			name={name}
			rules={rules}
			defaultValue={defaultValue}
			render={({ field: { onChange, onBlur, value, name } }) => (
				<CustomSelect
					onBlur={value => {
						if (hasError || shouldValidateOnBlur) {
							trigger(name);
						}
						onBlur(value);
					}}
					onChange={value => onChange(value)}
					value={value}
					name={name}
					error={hasError}
					helperText={hasError ? errorMessage : helperText}
					disabled={disabled}
					multiple={multiple}
					{...rest}
				/>
			)}
		/>
	);
};

FormSelect.propTypes = {
	options: PropTypes.array.isRequired
};

FormSelect.defaultProps = {
	multiple: false,
	helperText: " ",
	nullValue: null
};

export default FormSelect;